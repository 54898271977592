import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';

var config = {
  apiKey: 'AIzaSyDjoqvEJud-IRUhTPH1DkaSK97MBSjaBIs',
  authDomain: 'dewaste.firebaseapp.com',
  databaseURL: 'https://dewaste.firebaseio.com',
  projectId: 'dewaste',
  storageBucket: 'dewaste.appspot.com',
  messagingSenderId: '929795205654',
};
firebase.initializeApp(config);

export const db = firebase.firestore();

export default firebase;
