import React, { Component, Suspense } from 'react';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';

import styles from './App.module.css';

import { createMuiTheme } from '@material-ui/core';
import { StylesProvider, ThemeProvider, jssPreset } from '@material-ui/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { green } from '@material-ui/core/colors';
import { create } from 'jss';

import { PrerenderedComponent } from 'react-prerendered-component';
import withFirebaseAuth from 'react-with-firebase-auth';
import firebaseApp from './helpers/firebase';

const PrivateRoute = ({ render, signedIn, ...rest }) => {
  const renderRoute = (props) => {
    return signedIn ? render(props) : <Redirect to="/login" />;
  };
  return <Route {...rest} render={renderRoute} />;
};

const prefetchMap = new WeakMap();
const prefetchLazy = (LazyComponent) => {
  if (!prefetchMap.has(LazyComponent)) {
    prefetchMap.set(LazyComponent, LazyComponent._ctor());
  }
  return prefetchMap.get(LazyComponent);
};

const prerenderedLazy = (dynamicImport) => {
  const LazyComponent = React.lazy(dynamicImport);
  return React.memo((props) => (
    <PrerenderedComponent
      className={styles.prerendered}
      live={prefetchLazy(LazyComponent)}
    >
      <LazyComponent {...props} />
    </PrerenderedComponent>
  ));
};

const LandingPage = prerenderedLazy(() => import('./LandingPage'));
const Dashboard = prerenderedLazy(() => import('./dashboard/Dashboard'));
const Login = prerenderedLazy(() => import('./dashboard/Login'));
const Blog = prerenderedLazy(() => import('./blog/Blog'));
const UCRiversideLothianFeedback = prerenderedLazy(() =>
  import('./UCRiversideLothianFeedback')
);
const UCRiversideAberdeenFeedback = prerenderedLazy(() =>
  import('./UCRiversideAberdeenFeedback')
);
const Network = prerenderedLazy(() => import('./network/Network'));

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById('jss-insertion-point'),
});

// Use system font
const theme = createMuiTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: green,
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: JSON.parse('{}'),
    };
  }

  componentDidMount() {
    let userData = '{}';
    if (localStorage) {
      userData = localStorage.getItem('user');
    }
    this.setState({ user: JSON.parse(userData) });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.user !== prevProps.user) {
      if (localStorage) {
        if (this.props.user) {
          localStorage.setItem('user', JSON.stringify(this.props.user));
        } else {
          localStorage.removeItem('user');
        }
      }
      this.setState({ user: this.props.user });
    }
  }

  render() {
    return (
      <StylesProvider jss={jss}>
        <ThemeProvider theme={theme}>
          <Router>
            <Suspense fallback={<LinearProgress className={styles.loading} />}>
              <Switch>
                <Route path="/" exact render={(props) => <LandingPage />} />
                <Route path="/blog" render={(props) => <Blog />} />
                <Route
                  path="/network"
                  render={(props) => (
                    <Network
                      signup={this.props.createUserWithEmailAndPassword}
                      signin={this.props.signInWithEmailAndPassword}
                      signout={this.props.signOut}
                      user={this.state.user}
                    />
                  )}
                />
                <PrivateRoute
                  path="/dashboard"
                  signedIn={!!this.state.user}
                  render={(props) => (
                    <Dashboard
                      user={this.state.user}
                      signOut={this.props.signOut}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/login"
                  exact
                  render={(props) => (
                    <Login
                      signInWithGoogle={this.props.signInWithGoogle}
                      user={this.state.user}
                      {...props}
                    />
                  )}
                />
                <Route
                  path="/ucriverside/lothian/feedback"
                  exact
                  render={(props) => <UCRiversideLothianFeedback />}
                />
                <Route
                  path="/ucriverside/aberdeen/feedback"
                  exact
                  render={(props) => <UCRiversideAberdeenFeedback />}
                />
              </Switch>
            </Suspense>
          </Router>
        </ThemeProvider>
      </StylesProvider>
    );
  }
}

const firebaseAppAuth = firebaseApp.auth();
const providers = {
  googleProvider: new firebaseApp.auth.GoogleAuthProvider(),
};
export default withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(App);
